<template>
  <main class="layout-hasColG">
    <header class="layer-primary">
      <div class="main-img main-img-random">
        <img loading="lazy" src="https://cdn.maine-et-loire.fr/ecosysteme_ressources/img/random_une.png" alt="" width="1800" height="650" />
      </div>
    </header>

    <section class="section-content" id="section-content">
      <header>
        <ul id="site-nav-breadcrumbs" class="site-nav-breadcrumbs">
          <li>
            <router-link :to="{ name: 'accueil' }">Accueil</router-link>
          </li>
          <li>Liste des mairies</li>
        </ul>
        <h1>Liste des assistantes maternelles agréées par commune</h1>

        <p class="intro" id="introPage">
          Retrouvez ci-dessous les listes officielles et publiques que les Mairies mettent à disposition de leurs administrés. Sélectionnez la commune souhaitée et obtenez un document PDF présentant la liste complète des assistants maternels agréés.
        </p>
        <div class="toolbox"></div>
      </header>
      <div>
        <aside class="sidebar-secondary">
          <section class="pensebete">
            <h2>À noter</h2>
            <div class="ce-textpic ce-center ce-above">
              <div class="ce-bodytext">
                <p></p>
                <p class="message_alerte">
                  <span>&nbsp;</span>
                  Ces listes ne comportent pas les disponibilités. Pour obtenir les disponibilités,
                  <a title="Cliquez ici pour effectuer une recherche" href="/">effectuez une recherche.</a>
                </p>
                <p></p>
              </div>
            </div>
          </section>
        </aside>
        <article id="main-content">
          <!-- LISTE DES COMMUNES -->
          <h2>Télécharger votre liste</h2>

          <form @submit.prevent="submitForm">
            <label>
              Commune
              <select id="city" v-model="codeInsee" required>
                <option value="" selected disabled>Choisissez la commune</option>
                <option v-for="result in resultsCommunes" :value="result.id" :data-insee="result.codeInsee" :data-deleguee="result.codeInseeNouvelle ? true : false">
                  {{ result.libelle }}
                  <span v-if="result.idCommuneNouvelle" v-text="getCommuneNouvelle(result)"></span>
                </option>
              </select>
            </label>
            <input type="submit" value="Télécharger au format PDF" />
          </form>

          <div v-if="errored">
            {{ errorMessage }}
          </div>
        </article>
      </div>

      <footer>
        <div class="layer-news-list" id="news-container-401">
          <h2>Actualité de l'enfance en Maine-et-Loire</h2>
          <actualities></actualities>
        </div>
      </footer>
    </section>
  </main>
</template>

<script>
import actualities from '@/components/actualities.vue';

export default {
  name: 'Collectivite',
  components: {
    actualities
  },
  data() {
    return {
      resultsCommunes: [],
      codeInsee: '',
      errored: false,
      errorMessage: ''
    };
  },
  mounted() {
    this.$http
      .get('export/communes')
      .then((result) => {
        this.resultsCommunes = result.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      });
  },
  methods: {
    submitForm: function () {
      this.$http
        .get('export', {
          responseType: 'blob',
          params: {
            idCommune: city.options[city.selectedIndex].value
          }
        })
        .then((response) => {
          if (response.status == 204) {
            this.errored = true;
            this.errorMessage = 'Il n’y a pas d’assistants maternels dans cette commune';
          } else {
            this.errored = false;
            let url = URL.createObjectURL(new Blob([response.data])),
              cityName = city.options[city.selectedIndex].text.replace(/ /g, '_'),
              a = document.createElement('a');
            a.href = url;
            a.download = 'Liste_Assistants_Maternels_' + cityName + '.pdf';
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
          }
        })
        .catch((error) => {
          console.debug(error);
          this.errorMessage = error;
          this.errored = true;
        });
    },
    getCommuneNouvelle: function (commune) {
      let communeNouvelle = '';
      this.resultsCommunes.forEach((c) => {
        if (c.id == commune.idCommuneNouvelle) {
          communeNouvelle = '(' + c.libelle + ')';
          return;
        }
      });
      return communeNouvelle;
    }
  }
};
</script>